const ActivityStat = ({
  label,
  icon,
  value,
  unit,
}: {
  label: string;
  icon: string;
  value: string;
  unit?: string;
}) => (
  <div className="flex flex-col">
    <p className="text-xs font-medium text-muted-foreground">
      {label} {icon}
    </p>
    <div className="flex items-baseline">
      <p className="text-sm font-bold">{value}</p>
      {unit && <p className="text-xs font-light ml-1">{unit}</p>}
    </div>
  </div>
);

export default ActivityStat;
