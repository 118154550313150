import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Bar, BarChart, XAxis, YAxis } from "recharts";
import { TeamContribution } from "../../data/company/get-contribution-per-team";
import Typography from "../typography";
import { SportsLegend } from "../ui/sports-legend";

const chartConfig = {
  totalExp: {
    label: "Experience",
    color: "hsl(var(--chart-1))",
  },
  bikingExp: {
    label: "Biking",
    color: "hsl(var(--chart-1))",
  },
  runningExp: {
    label: "Running",
    color: "hsl(var(--chart-2))",
  },
  walkingExp: {
    label: "Walking",
    color: "hsl(var(--chart-3))",
  },
  swimmingExp: {
    label: "Swimming",
    color: "hsl(var(--chart-4))",
  },
} satisfies ChartConfig;

type ContributionPerTeamChartProps = {
  chartData: TeamContribution[];
};

export const ContributionPerTeamChart = (
  props: ContributionPerTeamChartProps
) => {
  return (
    <div className="w-full bg-white rounded-md border border-slate-200 p-4 mt-4">
      <Typography variant="h3">Contribution per team</Typography>
      <Typography variant="p" affects="muted" className="text-sm">
        Total experience contributed to company level
      </Typography>

      <ChartContainer config={chartConfig}>
        <BarChart
          accessibilityLayer
          data={props.chartData}
          layout="vertical"
          margin={{
            left: -10,
            top: 20,
          }}
        >
          <XAxis
            type="number"
            dataKey="totalExp"
            tickFormatter={(v: string) => v + "%"}
          />

          <YAxis
            dataKey="teamCodeName"
            type="category"
            tickLine={false}
            axisLine={false}
          />

          <ChartTooltip
            cursor={false}
            content={
              <ChartTooltipContent
                hideLabel
                formatter={(value, name, item, index) => (
                  <>
                    <div
                      className="h-2.5 w-2.5 shrink-0 rounded-[2px] bg-[--color-bg]"
                      style={
                        {
                          "--color-bg": `var(--color-${name})`,
                        } as React.CSSProperties
                      }
                    />
                    {chartConfig[name as keyof typeof chartConfig]?.label ||
                      name}
                    <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                      {value}
                      <span className="font-normal text-muted-foreground">
                        %
                      </span>
                    </div>
                    {/* Add this after the last item */}
                    {index === 3 && (
                      <div className="mt-1.5 flex basis-full items-center border-t pt-1.5 text-xs font-medium text-foreground">
                        Total
                        <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                          {item.payload.totalExp}
                          <span className="font-normal text-muted-foreground">
                            %
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                )}
              />
            }
          />

          {/* Bike */}
          <Bar
            dataKey="bikingExp"
            stackId="a"
            fill="var(--color-bikingExp)"
            radius={[5, 0, 0, 5]}
          />
          {/* Run */}
          <Bar
            dataKey="runningExp"
            stackId="a"
            fill="var(--color-runningExp)"
            radius={0}
          />
          {/* Walk */}
          <Bar
            dataKey="walkingExp"
            stackId="a"
            fill="var(--color-walkingExp)"
            radius={0}
          />
          {/* Swim */}
          <Bar
            dataKey="swimmingExp"
            stackId="a"
            fill="var(--color-swimmingExp)"
            radius={[0, 5, 5, 0]}
          />
        </BarChart>
      </ChartContainer>

      <SportsLegend />
    </div>
  );
};
