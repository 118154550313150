import { ChartConfig, ChartContainer } from "@/components/ui/chart";
import {
  Label,
  PolarGrid,
  PolarRadiusAxis,
  RadialBar,
  RadialBarChart,
} from "recharts";
import Typography from "../typography";

const chartConfig = {
  teamContribution: {
    label: "Contribution",
    color: "hsl(var(--chart-1))",
  },
  companyContribution: {
    label: "Contribution",
    color: "hsl(var(--chart-2))",
  },
} satisfies ChartConfig;

type UserXPContributionProps = {
  companyContribution: number;
  companyLevel: number;
};

export const UserXPContribution = (props: UserXPContributionProps) => {
  const companyContributionChartData = [
    {
      contribution: props.companyContribution,
      fill: "var(--color-companyContribution)",
    },
  ];

  return (
    <div className="h-fit w-full bg-white rounded-md border border-slate-200 p-4">
      <Typography variant="h3" className="text-center sm:text-left">
        Experience
      </Typography>

      <Typography
        variant="p"
        affects="muted"
        className="text-sm text-center sm:text-left"
      >
        Total experience contributed to the company
      </Typography>

      <div className="flex flex-col w-full justify-center">
        {/* Contribution to company level */}
        <div className="w-full text-center">
          <ChartContainer
            config={chartConfig}
            className="mx-auto aspect-square h-[200px] w-full"
          >
            <RadialBarChart
              data={companyContributionChartData}
              endAngle={props.companyContribution * 360} // X% of 360 (full circle)
              innerRadius={80}
              outerRadius={140}
            >
              <PolarGrid
                gridType="circle"
                radialLines={false}
                stroke="none"
                className="first:fill-muted last:fill-background"
                polarRadius={[86, 74]}
              />
              <RadialBar dataKey="contribution" background />
              <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
                <Label
                  content={({ viewBox }) => {
                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                      return (
                        <text
                          x={viewBox.cx}
                          y={viewBox.cy}
                          textAnchor="middle"
                          dominantBaseline="middle"
                        >
                          <tspan
                            x={viewBox.cx}
                            y={viewBox.cy}
                            className="fill-foreground text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold"
                          >
                            {(
                              companyContributionChartData[0].contribution * 100
                            ).toLocaleString()}
                            %
                          </tspan>
                          <tspan
                            x={viewBox.cx}
                            y={(viewBox.cy || 0) + 20}
                            className="fill-muted-foreground text-xs sm:text-sm"
                          >
                            {(
                              props.companyContribution * props.companyLevel
                            ).toFixed(2)}{" "}
                            / {props.companyLevel} levels
                          </tspan>
                        </text>
                      );
                    }
                  }}
                />
              </PolarRadiusAxis>
            </RadialBarChart>
          </ChartContainer>
        </div>
      </div>
    </div>
  );
};
