import { client } from "../client";

type ActivityReactWithEmojiRequestDTO = {
  activityId: string;
  emojiId: string;
};

export const reactToActivityWithEmoji = async (
  params: ActivityReactWithEmojiRequestDTO
) => {
  const res = await client.post(`/activities/${params.activityId}/reactions`, {
    emojiId: params.emojiId,
  });

  return res.data;
};
