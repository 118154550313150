import { ScrollArea } from "@/components/ui/scroll-area";
import { Activity as ActivityType } from "../../../data/activities/get-home";
import Typography from "../../typography";
import { Spinner } from "../spinner";
import Activity from "./activity";

type ActivityListProps = {
  activities: ActivityType[];
  isLoading: boolean;
};

export function ActivityList(props: ActivityListProps) {
  return (
    <div className="bg-white rounded-md border border-slate-200 p-2 sm:p-4 h-full">
      {!props.isLoading && props.activities.length === 0 && (
        <div className="flex flex-col h-full items-center justify-center">
          <Typography variant="h4">Welcome to Miles!</Typography>
          <Typography variant="p" className="text-center">
            There are no activities yet, so this page won't be very exciting{" "}
            <b>
              <i>for now</i>
            </b>{" "}
            😉
          </Typography>
        </div>
      )}

      {props.isLoading && (
        <div className="flex flex-col h-full items-center justify-center">
          <Spinner className="text-blue-400" size="large" />
        </div>
      )}

      {props.activities.length > 0 && (
        <ScrollArea className="h-[calc(100vh-4rem)]">
          <div className="space-y-4">
            {props.activities.map((activity) => (
              <Activity key={activity.id} activity={activity} />
            ))}
          </div>
        </ScrollArea>
      )}
    </div>
  );
}
