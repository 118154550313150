import { Card } from "@/components/ui/card";
import { XPBoostStats } from "../../data/auth/get-user-stats";
import Typography from "../typography";

export function XPBoostStatsCard({ stats }: { stats: XPBoostStats }) {
  return (
    <Card className="h-fit w-full bg-white rounded-md border border-slate-200 p-4">
      <Typography variant="h3" className="text-center sm:text-left">
        XP Boost Stats
      </Typography>
      <Typography variant="p" affects="muted" className="text-sm">
        Statistics regarding the use of XP Boosts.
      </Typography>

      <div className="grid text-sm mt-4">
        <div className="flex justify-between">
          <span className="text-muted-foreground">Available:</span>
          <span className="font-medium text-blue-400">
            {stats.nbAvailable} ({stats.totalPercentAvailable}%)
          </span>
        </div>

        <div className="flex justify-between mt-2">
          <span className="text-muted-foreground">Applied:</span>
          <span className="font-medium text-blue-400">
            {stats.nbApplied} ({stats.totalPercentUsed}%)
          </span>
        </div>

        <div className="flex justify-between mt-2">
          <span className="text-muted-foreground">Exp from boosts:</span>
          <span className="font-medium text-blue-400">
            {stats.totalXPGiven.toLocaleString()} EXP
          </span>
        </div>

        <div className="flex justify-between mt-2">
          <span className="text-muted-foreground">Forged:</span>
          <span className="font-medium text-blue-400">{stats.nbForged}</span>
        </div>
      </div>
    </Card>
  );
}
