import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import Typography from "../components/typography";
import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { createCompany } from "../data/setup/create-company";
import { joinCompanyByCode } from "../data/setup/join-company-by-code";
import { getFallbackName } from "../lib/name-utils";
import { useAuthStore } from "../stores/auth.store";

export const Setup = () => {
  const navigate = useNavigate();

  const setCompanyId = useAuthStore((store) => store.setCompanyId);

  const [joinCode, setJoinCode] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [avatarFallback, setAvatarFallback] = useState("");

  const [iconSrc, setIconSrc] = useState<string>();
  const [iconFile, setIconFile] = useState<File>();

  useEffect(() => {
    setAvatarFallback(getFallbackName(companyName));
  }, [companyName, setAvatarFallback]);

  const onClickJoinByCode = async () => {
    const trimmedJoinCode = joinCode.trim();

    if (!trimmedJoinCode) {
      toast.error("An error occured", {
        description: "Joining a company requires its company code.",
      });

      return;
    }

    const data = await joinCompanyByCode({ joinCode: trimmedJoinCode });
    setCompanyId(data.companyId, false);

    navigate("/company");
  };

  const onClickCreateCompany = async () => {
    const trimmedCompanyName = companyName.trim();

    if (!trimmedCompanyName) {
      toast.error("An error occured", {
        description: "The company must be given a name.",
      });

      return;
    }

    const { id: companyId } = await createCompany({
      name: trimmedCompanyName,
      iconFile,
    });

    setCompanyId(companyId, true);

    navigate("/company");
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setIconSrc(imageURL);
      setIconFile(file);
    }
  };

  return (
    <div className="flex flex-row h-full">
      <div className="h-full w-full lg:w-1/2 flex content-center justify-center items-center p-4 lg:p-0">
        <div className="flex flex-col text-center items-center gap-3 w-full max-w-md">
          {/* Titles */}
          <div>
            <Typography variant="h1" className="text-2xl lg:text-4xl">
              Welcome to Miles!
            </Typography>
            <Typography
              variant="p"
              className="text-slate-600 text-sm lg:text-base"
            >
              Create or join a company to get started.
            </Typography>
          </div>

          <Tabs defaultValue="employee" className="w-full">
            <TabsList className="w-full">
              <TabsTrigger value="employer" className="w-1/2">
                <p className="text-xs lg:text-sm">
                  I'm an <b>employer</b>
                </p>
              </TabsTrigger>
              <TabsTrigger value="employee" className="w-1/2">
                <p className="text-xs lg:text-sm">
                  I'm an <b>employee</b>
                </p>
              </TabsTrigger>
            </TabsList>

            <TabsContent value="employer">
              {/* Create */}
              <div className="flex flex-col gap-2 text-left w-full bg-white rounded-md border border-slate-200 p-4">
                <div className="flex flex-col gap-4 w-full items-start">
                  <div className="flex flex-col text-left gap-2">
                    <Label htmlFor="companyIcon">Company Icon</Label>
                    <div className="flex items-center gap-4">
                      <Avatar
                        size="large"
                        className="cursor-pointer"
                        onClick={() =>
                          document.getElementById("companyIcon")?.click()
                        }
                      >
                        <AvatarImage src={iconSrc} />
                        <AvatarFallback>{avatarFallback}</AvatarFallback>
                      </Avatar>
                      <div className="flex flex-col">
                        <Button
                          variant="outline"
                          onClick={() =>
                            document.getElementById("companyIcon")?.click()
                          }
                        >
                          Upload Icon
                        </Button>
                        <Typography
                          variant="p"
                          affects="muted"
                          className="text-xs mt-1"
                        >
                          Click to upload or change company icon
                        </Typography>
                      </div>
                    </div>
                    <input
                      id="companyIcon"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      hidden
                    />
                  </div>

                  <div className="flex flex-col text-left gap-1 w-full">
                    <Label htmlFor="companyName">Company Name</Label>
                    <Input
                      id="companyName"
                      type="text"
                      placeholder="Cola Coca"
                      value={companyName}
                      onChange={(event) => setCompanyName(event.target.value)}
                    />
                  </div>
                </div>

                <Button type="submit" onClick={onClickCreateCompany}>
                  Create your own company
                </Button>
              </div>
            </TabsContent>

            <TabsContent value="employee">
              {/* Join existing */}
              <div className="flex flex-col gap-1 text-left w-full bg-white rounded-md border border-slate-200 p-4">
                <Label htmlFor="companyCode">Company Code</Label>

                <div className="flex flex-col lg:flex-row items-center gap-2">
                  <Input
                    id="companyCode"
                    type="text"
                    placeholder="jcmp_**************************"
                    onChange={(event) => setJoinCode(event.target.value)}
                    className="w-full"
                  />

                  <Button
                    type="submit"
                    onClick={onClickJoinByCode}
                    className="w-full lg:w-auto mt-2 lg:mt-0"
                  >
                    Join your company
                  </Button>
                </div>

                <Typography
                  variant="p"
                  affects="muted"
                  className="text-xs lg:text-sm"
                >
                  The company code provided to you by your employer.
                </Typography>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </div>

      <div className="w-1/2 h-full bg-[url('/runner.jpg')] bg-cover bg-center bg-no-repeat hidden md:flex" />
    </div>
  );
};
