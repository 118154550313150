import { client } from "../../client";
import { Emoji } from "../../emojis/types";

type Cell = {
  x: number;
  y: number;
  energyRequirement: number;
  discount: number;
  claimedAt: string | null;
};

export type ClaimedCellReward = {
  x: number;
  y: number;
  onClaimEnergyReward: number;
  onClaimExpBoostReward: number;
  onClaimEmojiReward: string;
  emoji?: Emoji;
  energyRequirement: number;
  discount: number;
};

type GridUser = {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  energy: number;
};

type GridStats = {
  totalEnergyRewarded: number;
  totalXPBoostRewarded: number;
  totalXPBoostCellsClaimed: number;
  totalEnergyCellsClaimed: number;
  totalEmojisRewarded: number;
  avgPricePerCell: number;
  remainingCellsCost: number;
  totalEnergyPaid: number;
  totalEnergyCost: number;
};

export type Grid = {
  id: string;
  level: number;
  claimedCellRewards: ClaimedCellReward[];
  isFinished: boolean;
  cells: Cell[];
  previousGridId?: string;
  nextGridId?: string;
  user: GridUser;
  stats: GridStats;
};

type GetGridByIdRequestDTO = {
  id: string;
};

type GetGridByIdResponseDTO = Grid;

export const getGridById = async (params: GetGridByIdRequestDTO) => {
  const res = await client.get<GetGridByIdResponseDTO>(
    `/grid-reveal/${params.id}`
  );

  return res.data;
};
