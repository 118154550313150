import dayjs from "dayjs";
import { CalendarIcon, PlusIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { uploadActivity } from "../../data/activities/upload-activity";
import { uploadManualActivity } from "../../data/activities/upload-manual-activity";
import { getUserById } from "../../data/auth/get-user";
import { timeToSeconds } from "../../lib/date-utils";
import { initiateLinkWithStrava } from "../../lib/strava-utils";
import { useAuthStore } from "../../stores/auth.store";
import Typography from "../typography";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Separator } from "../ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";

const maxDistances = {
  walking: 10,
  running: 10,
  biking: 25,
  swimming: 4,
};

export const UploadActivityDialog = () => {
  const navigate = useNavigate();

  const userId = useAuthStore((store) => store.userId);

  const [hasLinkedStrava, setHasLinkedStrava] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  const [sport, setSport] = useState("");
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [date, setDate] = useState<Date>(dayjs().toDate());
  const [error, setError] = useState("");

  const oneMonthAgo = dayjs().subtract(1, "month");

  // This is overkill. We shouldn't have to make a GET request on the user
  // just to determine if they have linked their Strava. To rework later.
  const loadUser = async () => {
    const data = await getUserById({
      userId,
    });

    setHasLinkedStrava(!!data.stravaUserId);
  };

  useEffect(() => {
    if (sport && distance) {
      const distanceNum = parseFloat(distance);
      const maxDistance = maxDistances[sport as keyof typeof maxDistances];

      if (distanceNum > maxDistance) {
        setError(
          `For ${sport} activities over ${maxDistance}km, we recommend using a GPS device or smartwatch for more accurate tracking.`
        );
      } else {
        setError("");
      }
    }
  }, [sport, distance]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const finalDistance = Number(distance);
    const finalDuration = timeToSeconds(duration);

    if (error || !date || sport === "") {
      toast.warning("Invalid data provided", {
        description: "Please double check all values.",
      });

      return;
    }

    await uploadManualActivity({
      sportType: sport,
      distance: finalDistance,
      duration: finalDuration,
      date: date.toISOString(),
    });

    navigate(0);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const onClickUpload = async () => {
    if (file) {
      await uploadActivity({
        file,
      });

      navigate(0);
    }
  };

  return (
    <Dialog onOpenChange={(value) => value && loadUser()}>
      <DialogTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="rounded-md"
          aria-label="Upload activity"
        >
          <PlusIcon className="size-5" />
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-md w-full mx-auto px-2 sm:px-4">
        <DialogHeader>
          <DialogTitle>Log Your Activity</DialogTitle>
          <DialogDescription>
            Record your sports activities and track your progress
          </DialogDescription>
        </DialogHeader>

        <Tabs defaultValue="manual" className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="manual">Manual Entry</TabsTrigger>
            <TabsTrigger value="gpx">GPX Upload</TabsTrigger>
          </TabsList>

          <TabsContent value="manual">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <Label>Sport Type</Label>

                <div className="flex justify-between gap-4">
                  {Object.keys(maxDistances).map((key) => (
                    <Button
                      key={key}
                      type="button"
                      variant={sport === key ? "secondary" : "outline"}
                      className="w-1/4 aspect-square p-2"
                      onClick={() => setSport(key)}
                    >
                      {key.charAt(0).toUpperCase()}
                      {key.slice(1)}
                    </Button>
                  ))}
                </div>
              </div>

              <div className="grid grid-cols-3 gap-2">
                <div className="space-y-2">
                  <Label htmlFor="distance">Distance (km)</Label>
                  <Input
                    id="distance"
                    type="number"
                    step="0.1"
                    min="1"
                    value={distance}
                    placeholder="1"
                    onChange={(e) => setDistance(e.target.value)}
                    required
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="duration">Duration</Label>
                  <Input
                    id="duration"
                    type="time"
                    step="1"
                    value={duration}
                    placeholder="600"
                    onChange={(e) => setDuration(e.target.value)}
                    required
                  />
                </div>

                <div className="space-y-2">
                  <Label>Date</Label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={"outline"}
                        className={`w-full justify-start text-left font-normal ${
                          !date && "text-muted-foreground"
                        }`}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date ? dayjs(date).format("MMM D") : "Pick a date"}
                      </Button>
                    </PopoverTrigger>

                    <PopoverContent className="w-auto p-0">
                      <Calendar
                        mode="single"
                        selected={date}
                        onSelect={(date) =>
                          setDate(
                            dayjs(date)
                              .set("hour", new Date().getHours())
                              .set("minute", new Date().getMinutes())
                              .toDate()
                          )
                        }
                        disabled={(date) =>
                          date > new Date() || date < oneMonthAgo.toDate()
                        }
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </div>
              </div>

              {error && (
                <Alert variant="destructive">
                  <AlertTitle>You're really pushing your limits!</AlertTitle>
                  <AlertDescription className="text-black">
                    {error}
                  </AlertDescription>
                </Alert>
              )}

              <Button
                type="submit"
                className="w-full"
                disabled={!!error || sport === "" || Number(distance) < 1}
              >
                Log Activity
              </Button>
            </form>
          </TabsContent>

          <TabsContent value="gpx">
            <div className="flex flex-row gap-2 w-full">
              <Input
                id="activityFile"
                type="file"
                onChange={handleFileChange}
                accept="application/gpx+xml"
                className="flex-grow"
              />

              <DialogClose asChild>
                <Button onClick={onClickUpload} disabled={!file}>
                  Upload
                </Button>
              </DialogClose>
            </div>
          </TabsContent>
        </Tabs>

        <div className="flex flex-row gap-2 w-full items-center justify-center">
          <Separator className="w-2/5" />
          <Typography variant="p" affects="muted">
            OR
          </Typography>
          <Separator className="w-2/5" />
        </div>

        {hasLinkedStrava ? (
          <a className="text-blue-400 text-center">
            You've successfully linked your Strava profile 🎉
          </a>
        ) : (
          <Button
            className="bg-orange-500 hover:bg-orange-400 w-full"
            onClick={initiateLinkWithStrava}
          >
            Link with Strava
          </Button>
        )}

        <Typography
          variant="p"
          affects="muted"
          className="text-center text-sm px-1"
        >
          Linking your Strava account will automatically upload your next
          activities.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
