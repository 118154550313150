import { useEffect, useState } from "react";
import { CompanyProfileHeader } from "../components/cards/company-profile-header";
import { ContributionPerTeamChart } from "../components/charts/contribution-per-team";
import { TotalActivitiesBarChart } from "../components/charts/total-activities";
import { CompanyMembersTable } from "../components/tables/company-member-table";
import TeamList from "../components/ui/teams/team-list";
import { Company, getCompany } from "../data/company/get-company";
import {
  getCompanyActivityStats,
  MonthlyActivityStats,
} from "../data/company/get-company-activity-stats";
import {
  CompanyMember,
  getCompanyMembers,
} from "../data/company/get-company-members";
import {
  getContributionPerTeam,
  TeamContribution,
} from "../data/company/get-contribution-per-team";
import { useAuthStore } from "../stores/auth.store";
import { Setup } from "./Setup";

export const CompanyPage = () => {
  const companyId = useAuthStore((store) => store.companyId);

  const [company, setCompany] = useState<Company>();
  const [members, setMembers] = useState<Array<CompanyMember>>([]);
  const [contributionPerTeam, setContributionPerTeam] = useState<
    Array<TeamContribution>
  >([]);
  const [activityStats, setActivityStats] = useState<
    Array<MonthlyActivityStats>
  >([]);

  const loadCompanyData = async () => {
    const data = await getCompany({
      companyId,
    });

    setCompany(data);
  };

  const loadCompanyMembers = async () => {
    const data = await getCompanyMembers({
      companyId,
    });

    setMembers(data);
  };

  const loadContributionPerTeam = async () => {
    const data = await getContributionPerTeam({
      companyId,
    });

    setContributionPerTeam(data);
  };

  const loadCompanyActivityStats = async () => {
    const data = await getCompanyActivityStats({
      companyId,
    });

    setActivityStats(data);
  };

  useEffect(() => {
    if (companyId) {
      loadCompanyData();
      loadCompanyMembers();
      loadContributionPerTeam();
      loadCompanyActivityStats();
    }
  }, [companyId]);

  const removeCompanyMember = (userId: string) => {
    const removedMemberIdx = members.findIndex((m) => m.id === userId);

    if (removedMemberIdx !== -1) {
      const newMembers = [...members];
      newMembers.splice(removedMemberIdx, 1);

      setMembers(newMembers);
    }
  };

  if (!companyId) {
    return <Setup />;
  }

  return (
    <div>
      <CompanyProfileHeader
        avatarUrl={company?.avatarUrl || ""}
        backgroundStyle={company?.profileBackground?.style || ""}
        level={company?.level || 0}
        name={company?.name || ""}
        experience={company?.experience || 0}
        nextLevelExp={company?.nextLevelExp || 0}
        previousLevelExp={company?.previousLevelExp || 0}
      />

      <div className="flex flex-col md:flex-row gap-4 max-h-none sm:max-h-[24rem]">
        {/* Teams */}
        <TeamList
          teams={company?.teams || []}
          refreshCompanyData={loadCompanyData}
        />

        {/* Contribution per team */}
        <ContributionPerTeamChart
          chartData={contributionPerTeam.filter((t) => t.totalExp > 0)}
        />

        {/* Total activities per sport */}
        <TotalActivitiesBarChart chartData={activityStats} />
      </div>

      <div className="w-full pb-20 md:pb-4">
        <CompanyMembersTable
          members={members}
          removeMember={removeCompanyMember}
        />

        {/* TODO: show activities table? */}
      </div>
    </div>
  );
};
