import { MinigameUpgrade } from "../data/minigames/get-upgrades";
import { MinigameId } from "./minigame-utils";

type UpgradeInfo = {
  title: string;
  description: string;
  unit?: string;
  cardStyle?: string;
};

type MinigameUpgradeConfig = Record<string, UpgradeInfo>;

type AllMinigameUpgradeConfigs = {
  [key in MinigameId]?: MinigameUpgradeConfig;
};

const UPGRADE_CONFIGS: AllMinigameUpgradeConfigs = {
  GRID_REVEAL: {
    DISCOUNT_PER_CELL: {
      title: "Neighbor's Discount",
      description:
        "Cells adjacent to your purchased cells receive a [2/4/6/8/10]% discount.",
    },
    XP_BOOST_CHANCE: {
      title: "Lucky Learner",
      description:
        "Increases the chance of XP boost rewards per cell to [20/22/24/26/28/30]%.",
      unit: "%",
    },
    MIN_ENERGY_RETURN: {
      title: "Energy Floor",
      description:
        "Increases the minimum energy refund from reward cells to [20/25/30/35/40]%.",
      unit: "%",
    },
    MAX_ENERGY_RETURN: {
      title: "Energy Ceiling",
      description:
        "Increases the maximum energy refund from reward cells to [80/85/90/95/100]%.",
      unit: "%",
    },
    EASY_XP_BOOST: {
      title: "Beginner's Boost",
      description:
        "Increases minimum XP boost from easy cells [5/7/9/11/13/15]%.",
      unit: "%",
      cardStyle: "border-blue-400",
    },
    MEDIUM_XP_BOOST: {
      title: "Steady Climber",
      description:
        "Increases minimum XP boost from medium cells to [15/17/19/21/23/25]%.",
      unit: "%",
      cardStyle: "border-purple-400",
    },
    HARD_XP_BOOST: {
      title: "Expert's Edge",
      description:
        "Increases minimum XP boost from hard cells to [25/27/29/31/33/35]%.",
      unit: "%",
      cardStyle: "border-red-400",
    },
    EMOJI_CHANCE: {
      title: "➕➕ Emojis",
      description:
        "Increases the chance to get emojis from energy cells to [20/21/22/24/27/30]%.",
      unit: "%",
    },
  },
  XP_FORGE: {
    DISCOUNT: {
      title: "Discount",
      description:
        "Applies a [0/20/40/60/80/100]% discount to the price of forging an XP Boost.",
      unit: "%",
    },
    MIN_MULTIPLIER: {
      title: "Purity",
      description:
        "Boost the minimum purity of a forged XP Boost to [75/80/85/90/95/100]%.",
      unit: "%",
    },
  },
};

const getUpgradeOrder = (minigameId: MinigameId) => {
  if (UPGRADE_CONFIGS[minigameId]) {
    return Object.keys(UPGRADE_CONFIGS[minigameId]);
  }

  return [];
};

export const sortUpgrades =
  (minigameId: MinigameId) => (a: MinigameUpgrade, b: MinigameUpgrade) => {
    const order = getUpgradeOrder(minigameId);

    const indexA = order.indexOf(a.upgradeType);
    const indexB = order.indexOf(b.upgradeType);

    if (indexA === -1) return 1; // Put any unknown upgrades at the end
    if (indexB === -1) return -1;

    return indexA - indexB;
  };

export function getUpgradeInfo(
  minigameId: MinigameId,
  upgradeType: string
): UpgradeInfo | null {
  const minigameConfig = UPGRADE_CONFIGS[minigameId];
  if (!minigameConfig) {
    return null;
  }

  const upgradeInfo = minigameConfig[upgradeType];
  if (!upgradeInfo) {
    return null;
  }

  return upgradeInfo;
}
