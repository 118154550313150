import { Emoji, emojiColorsByRarity } from "../../../data/emojis/types";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { Separator } from "../separator";

type EmojiReactionProps = Emoji & {
  amount: number;
  isHighlighted: boolean;
  users: { id: string; firstName: string; lastName: string; amount: number }[];
};

export const EmojiReaction = (props: EmojiReactionProps) => {
  return (
    <Popover>
      <PopoverTrigger>
        <div
          className={`h-full w-full flex flex-row gap-2 px-2 py-1 border rounded-md bg-opacity-100 min-w-16 max-w-20 min-h-8 max-h-12
        ${emojiColorsByRarity[props.rarity]}
        ${props.isHighlighted && "ring-1 ring-blue-400"}
      `}
        >
          <picture>
            <source
              srcSet={`https://fonts.gstatic.com/s/e/notoemoji/latest/${props.id}/512.webp`}
              type="image/webp"
              width="20"
              height="20"
            />
            <img
              src={`https://fonts.gstatic.com/s/e/notoemoji/latest/${props.id}/512.webp`}
              alt={props.name}
              width="20"
              height="20"
            />
          </picture>

          <p className="font-normal text-sm">{props.amount}</p>
        </div>
      </PopoverTrigger>

      <PopoverContent
        className="bg-white text-black border border-gray-200"
        align="start"
      >
        <div className="flex flex-col gap-2">
          {props.xpReward > 0 && (
            <>
              <p>
                {props.amount}x {props.fallback} worth{" "}
                {props.amount * props.xpReward} XP
              </p>
              <Separator />
            </>
          )}

          <ul className="list-disc pl-4">
            {props.users
              .sort((a, b) => b.amount - a.amount)
              .map((user) => (
                <li key={user.id}>
                  {user.amount && props.xpReward
                    ? `${user.amount}x ${user.firstName} ${user.lastName}`
                    : `${user.firstName} ${user.lastName}`}
                </li>
              ))}
          </ul>
        </div>
      </PopoverContent>
    </Popover>
  );
};
