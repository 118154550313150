import { Progress } from "@/components/ui/progress";

interface LevelProgressProps {
  totalExp: number;
  currentLevel: number;
  previousLevelExp: number;
  nextLevelExp: number;
  darkText?: boolean;
}

export function LevelProgress({
  totalExp,
  currentLevel,
  previousLevelExp,
  nextLevelExp,
  darkText,
}: LevelProgressProps) {
  const xpForCurrentLevel = totalExp - previousLevelExp;
  const xpRequiredForNextLevel = nextLevelExp - previousLevelExp;
  const progress = (xpForCurrentLevel / xpRequiredForNextLevel) * 100;

  return (
    <div className="w-full max-w-md p-4 rounded-md">
      <div className="flex justify-between items-center">
        <span
          className={`text-sm font-medium ${
            darkText ? "text-black" : "text-white"
          }`}
        >
          Level {currentLevel}
        </span>

        <div
          className={`text-center text-sm text-muted-foreground ${
            darkText ? "text-black" : "text-white"
          }`}
        >
          {xpForCurrentLevel} / {xpRequiredForNextLevel} XP
        </div>

        <span
          className={`text-sm text-muted-foreground ${
            darkText ? "text-black" : "text-white"
          }`}
        >
          Level {currentLevel + 1}
        </span>
      </div>

      <div className="relative">
        <Progress value={progress} className="h-4 rounded-md border bg-white" />
      </div>
    </div>
  );
}
