import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useNavigate } from "react-router-dom";
import { Grid } from "../../../data/minigames/grid-reveal/get-grid-by-id";
import { getFallbackName } from "../../../lib/name-utils";
import Typography from "../../typography";
import { Avatar, AvatarFallback, AvatarImage } from "../avatar";
import { Badge } from "../badge";

type InfoSectionProps = {
  grid: Grid | null;
  setHighlightedCell: (cell: { x: number; y: number } | null) => void;
};

export const InfoSection = ({ grid }: InfoSectionProps) => {
  const navigate = useNavigate();
  const isSmallDevice = useMediaQuery("only screen and (max-width: 768px)");

  const UserInfo = () => (
    <>
      {grid?.user && (
        <div className="flex items-center gap-2">
          <Avatar
            affects="noborder"
            className="hover:cursor-pointer"
            onClick={() => navigate(`/profile/${grid.user.id}`)}
          >
            <AvatarImage src={grid.user.avatarUrl} alt="Avatar" />
            <AvatarFallback>
              {getFallbackName(grid.user.firstName, grid.user.lastName)}
            </AvatarFallback>
          </Avatar>

          <div>
            <Typography
              variant="p"
              affects="link"
              onClick={() => navigate(`/profile/${grid.user.id}`)}
            >
              {grid.user.firstName} {grid.user.lastName}
            </Typography>
            <Badge
              variant="secondary"
              className="bg-blue-400 bg-opacity-10 text-md"
            >
              <Typography variant="p" affects="muted" className="text-blue-400">
                {grid.user.energy} left ⚡
              </Typography>
            </Badge>
          </div>
        </div>
      )}
    </>
  );

  const Rules = () => (
    <div>
      <Typography variant="h3" className="mb-2">
        Rules
      </Typography>
      <ul className="list-disc pl-4">
        <li>Use Energy to claim cells on the grid</li>
        <li>Claiming cells rewards Energy or an XP Boost</li>
        <li>Cells adjacent to claimed cells get a discount</li>
        <li>Claim all cells to advance to the next grid level</li>
      </ul>
    </div>
  );

  const Stats = () => (
    <div>
      <Typography variant="h3" className="mb-2">
        Stats
      </Typography>
      <div className="space-y-4">
        <div>
          <Typography variant="h4" className="text-sm font-semibold mb-1">
            XP Boost
          </Typography>
          <div className="flex flex-wrap gap-2">
            <Badge variant="secondary" className="bg-blue-400 bg-opacity-10">
              Rewarded: {grid?.stats.totalXPBoostRewarded}%
            </Badge>
            <Badge variant="secondary" className="bg-blue-400 bg-opacity-10">
              Cells: {grid?.stats.totalXPBoostCellsClaimed}
            </Badge>
          </div>
        </div>
        <div>
          <Typography variant="h4" className="text-sm font-semibold mb-1">
            Energy
          </Typography>
          <div className="flex flex-wrap gap-2">
            <Badge variant="secondary" className="bg-blue-400 bg-opacity-10">
              Rewarded: {grid?.stats.totalEnergyRewarded} ⚡
            </Badge>
            <Badge variant="secondary" className="bg-blue-400 bg-opacity-10">
              Cells: {grid?.stats.totalEnergyCellsClaimed}
            </Badge>
            <Badge variant="secondary" className="bg-blue-400 bg-opacity-10">
              Emojis: {grid?.stats.totalEmojisRewarded}
            </Badge>
          </div>
        </div>
        <div>
          <Typography variant="h4" className="text-sm font-semibold mb-1">
            Energy Cost
          </Typography>
          <div className="flex flex-wrap gap-2">
            <Badge variant="secondary" className="bg-blue-400 bg-opacity-10">
              Avg: {grid?.stats.avgPricePerCell?.toFixed(2) || 0} ⚡
            </Badge>
            <Badge variant="secondary" className="bg-blue-400 bg-opacity-10">
              Remaining: {grid?.stats.remainingCellsCost} ⚡
            </Badge>
          </div>
        </div>
        <div>
          <Typography variant="h4" className="text-sm font-semibold mb-1">
            ROI
          </Typography>
          <div className="flex flex-wrap gap-2">
            <Badge variant="secondary" className="bg-blue-400 bg-opacity-10">
              Total paid: {grid?.stats.totalEnergyPaid || 0} ⚡
            </Badge>
            <Badge variant="secondary" className="bg-blue-400 bg-opacity-10">
              Total cost: {grid?.stats.totalEnergyCost || 0} ⚡
            </Badge>
          </div>
        </div>
      </div>
    </div>
  );

  const History = () => (
    <div>
      <Typography variant="h3" className="mb-2">
        Rewards History
      </Typography>

      <div className="w-full h-full relative overflow-auto">
        <Table>
          <TableHeader className="sticky top-0 bg-white">
            <TableRow>
              <TableHead className="w-20">Position</TableHead>
              <TableHead>Cost</TableHead>
              <TableHead>Reward</TableHead>
              <TableHead>Emoji</TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {grid?.claimedCellRewards.map((cell, index) => (
              <TableRow
                key={index}
                className="hover:bg-gray-100"

                // Note: disabled due to flickering avatar.
                // Can re-add if people actually want this, otherwise clean up.
                // onMouseEnter={() =>
                //   setHighlightedCell({ x: cell.x, y: cell.y })
                // }
                // onMouseLeave={() => setHighlightedCell(null)}
              >
                <TableCell className="font-medium">
                  [{cell.x}, {cell.y}]
                </TableCell>

                <TableCell>
                  -
                  {Math.round(
                    cell.energyRequirement -
                      (cell.energyRequirement * cell.discount) / 100
                  )}{" "}
                  ⚡
                </TableCell>

                <TableCell>
                  {cell.onClaimEnergyReward > 0
                    ? `+${cell.onClaimEnergyReward} ⚡`
                    : `${cell.onClaimExpBoostReward}% XP Boost`}
                </TableCell>

                <TableCell>
                  {cell.emoji
                    ? `${cell.emoji.fallback} (${cell.emoji.rarity})`
                    : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-4 bg-white border border-gray-200 rounded-md p-4 h-full overflow-hidden pb-16 sm:pb-0">
      <div className="flex flex-col">
        <Typography variant="h2">Info</Typography>
        <UserInfo />
      </div>

      {isSmallDevice ? (
        <div className="flex flex-col gap-6">
          <Rules />
          <Stats />
          <History />
        </div>
      ) : (
        <Tabs defaultValue="history" className="w-full">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="rules">Rules</TabsTrigger>
            <TabsTrigger value="stats">Stats</TabsTrigger>
            <TabsTrigger value="history">History</TabsTrigger>
          </TabsList>

          <TabsContent value="rules" className="mt-4">
            <Rules />
          </TabsContent>

          <TabsContent value="stats" className="mt-4">
            <Stats />
          </TabsContent>

          <TabsContent value="history" className="mt-4">
            <History />
          </TabsContent>
        </Tabs>
      )}
    </div>
  );
};
