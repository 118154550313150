import { useState } from "react";
import {
  Activity,
  EmojiReaction as EmojiReactionType,
} from "../../../data/activities/get-home";
import { reactToActivityWithEmoji } from "../../../data/emojis/activity-react-with-emoji";
import { Emoji, emojiRarityOrder } from "../../../data/emojis/types";
import { getGroupedReactions } from "../../../lib/emoji-reactions-utils";
import { useAuthStore } from "../../../stores/auth.store";
import { EmojiReaction } from "./emoji-reaction";
import { Emojinventory } from "./emojinventory";

const ActivityEmojiReactions = ({ activity }: { activity: Activity }) => {
  const userId = useAuthStore((store) => store.userId);

  // The EmojiBuffer is an array that contains the emoji reactions the user did this reload,
  // in order to avoid reloading the entire homepage activities when reacting to an activity.
  const [emojiBuffer, setEmojiBuffer] = useState<
    Record<string, EmojiReactionType>
  >({});

  const reactWithEmoji = async (activityId: string, emoji: Emoji) => {
    await reactToActivityWithEmoji({
      activityId,
      emojiId: emoji.id,
    });

    const newReaction = {
      emojiId: emoji.id,
      fallback: emoji.fallback,
      id: emoji.id,
      name: emoji.name,
      rarity: emoji.rarity,
      user: {
        firstName: "You",
        lastName: "",
        id: userId,
      },
    };

    setEmojiBuffer((prev) => ({
      ...prev,
      [activityId]: newReaction,
    }));
  };

  return (
    <div className="grid grid-flow-col gap-2 justify-start w-full h-full bg-white border-t border-slate-200 pt-2 mt-1">
      <Emojinventory
        onEmojiClick={(emoji) => reactWithEmoji(activity.id, emoji)}
        isLocked={
          activity.author.id === userId ||
          !!activity.reactions.find(
            (reaction) => reaction.user.id === userId
          ) ||
          !!emojiBuffer[activity.id]
        }
      />

      {activity.author.id !== userId &&
        activity.reactions.length === 0 &&
        !emojiBuffer[activity.id] && (
          <p className="h-auto self-center text-sm">
            Be the first to react to this activity!
          </p>
        )}

      {getGroupedReactions(activity, emojiBuffer)
        .sort((a, b) => {
          const rarityDiff =
            emojiRarityOrder.indexOf(a.rarity) -
            emojiRarityOrder.indexOf(b.rarity);
          return rarityDiff === 0
            ? b.users.length - a.users.length
            : rarityDiff;
        })
        .map((emoji) => (
          <EmojiReaction
            key={emoji.id}
            {...emoji}
            xpReward={0}
            amount={emoji.users.length}
            users={emoji.users.map((u) => ({ ...u, amount: 1 }))}
            isHighlighted={
              !!emoji.users.find((userReaction) => userReaction.id === userId)
            }
          />
        ))}
    </div>
  );
};

export default ActivityEmojiReactions;
