import dayjs from "dayjs";
import { Lock } from "lucide-react";

type WeekData = {
  week: number;
  weekEndDate: string;
  progress: number;
  objective: number;
  reward: number;
  onClick: () => void;
};

export const TrainingOverviewCard: React.FC<
  WeekData & { isSelected: boolean }
> = ({
  week,
  weekEndDate,
  progress,
  objective,
  reward,
  isSelected,
  onClick,
}) => {
  const progressPercentage = Math.min((progress / objective) * 100, 100);
  const isRewardAchieved = progressPercentage >= 80;
  const isLocked = dayjs(weekEndDate).isBefore(dayjs(), "day");

  return (
    <div
      className={`rounded-md border border-slate-200 p-2 bg-white shadow-sm hover:cursor-pointer
        ${isSelected ? "ring-2 ring-blue-400" : ""}
        ${isLocked ? "opacity-50 bg-slate-100" : ""}
      `}
      onClick={onClick}
    >
      <div className="flex items-center space-x-4">
        <div className="w-16">
          <span className="text-xs text-gray-400 uppercase block">Week</span>

          <div className="flex flex-row gap-2 items-center">
            {isLocked && <Lock className="w-4 h-4 text-gray-500" />}
            <span className="text-lg font-bold">{week}</span>
          </div>
        </div>

        <div className="flex-grow">
          <div className="flex text-center mb-1 justify-between">
            <div className="flex flex-row gap-1 items-baseline">
              <span className="text-sm font-medium">
                {progress > 0 ? progress.toFixed(1) : 0}
              </span>
              <span className="text-xs font-small text-gray-400">KM</span>
            </div>

            <div className="flex flex-row gap-1 items-baseline">
              <span className="text-sm font-medium">{objective}</span>
              <span className="text-xs font-small text-gray-400">KM</span>
            </div>
          </div>

          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
              className={`h-2.5 rounded-full ${
                isRewardAchieved ? "bg-green-500" : "bg-blue-400"
              }`}
              style={{ width: `${progressPercentage}%` }}
            />
          </div>
        </div>

        <div className="w-20 text-right">
          <span
            className={`font-semibold ${
              isRewardAchieved ? "text-green-500" : "text-gray-700"
            }`}
          >
            {reward} XP
          </span>
        </div>
      </div>
    </div>
  );
};
