import { Activity, EmojiReaction } from "../data/activities/get-home";
import { EmojiRarity, emojiRarityOrder } from "../data/emojis/types";

type GroupedEmojiReaction = {
  id: string;
  fallback: string;
  name: string;
  rarity: EmojiRarity;
  emojiId: string;
  users: Array<{
    id: string;
    firstName: string;
    lastName: string;
  }>;
};

export const groupReactions = (
  reactions: EmojiReaction[]
): GroupedEmojiReaction[] => {
  return Object.values(
    reactions.reduce((acc, reaction) => {
      const key = reaction.emojiId;

      if (!acc[key]) {
        acc[key] = {
          id: reaction.id,
          fallback: reaction.fallback,
          name: reaction.name,
          rarity: reaction.rarity,
          emojiId: reaction.emojiId,
          users: [],
        };
      }

      acc[key].users.push({
        id: reaction.user.id,
        firstName: reaction.user.firstName,
        lastName: reaction.user.lastName,
      });

      return acc;
    }, {} as Record<string, GroupedEmojiReaction>)
  );
};

export const getGroupedReactions = (
  activity: Activity,
  buffer: Record<string, EmojiReaction>
) => {
  const reactions = buffer[activity.id]
    ? [...activity.reactions, buffer[activity.id]]
    : activity.reactions;

  return groupReactions(reactions).sort((a, b) => {
    const rarityDiff =
      emojiRarityOrder.indexOf(a.rarity) - emojiRarityOrder.indexOf(b.rarity);
    return rarityDiff === 0 ? b.users.length - a.users.length : rarityDiff;
  });
};
