import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { SmilePlus } from "lucide-react";
import { useState } from "react";
import {
  getUserEmojiventory,
  UserEmoji,
} from "../../../data/emojis/get-user-emojis";
import { emojiRarityOrder } from "../../../data/emojis/types";

type EmojinventoryProps = {
  onEmojiClick: (emoji: UserEmoji) => void;
  isLocked: boolean;
};

export const Emojinventory = (props: EmojinventoryProps) => {
  const [userEmojis, setUserEmojis] = useState<UserEmoji[]>([]);
  const [open, setOpen] = useState(false);

  const loadUserEmojis = async () => {
    const data = await getUserEmojiventory();
    setUserEmojis(data);
  };

  return (
    <Popover
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen);

        if (isOpen) {
          loadUserEmojis();
        }
      }}
    >
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className={`flex items-center space-x-1 ${
            props.isLocked && "bg-gray-300"
          }`}
          disabled={props.isLocked}
        >
          <SmilePlus className="h-4 w-4" />
          <span className="sr-only">Add reaction</span>
        </Button>
      </PopoverTrigger>

      <PopoverContent className="p-0" side="top" align="start">
        <Command>
          <CommandInput placeholder="Search emojis..." />

          <CommandList>
            <CommandEmpty>No emojis found.</CommandEmpty>
            <CommandGroup>
              {userEmojis
                .sort((a, b) => {
                  // Check zero amounts first (excluding CORE)
                  if (a.amount === 0 && b.amount !== 0 && b.rarity !== "CORE")
                    return 1;
                  if (b.amount === 0 && a.amount !== 0 && a.rarity !== "CORE")
                    return -1;

                  // Handle CORE rarity
                  if (a.rarity === "CORE" && b.amount === 0) return -1;
                  if (b.rarity === "CORE" && a.amount === 0) return 1;

                  // If both items have amount 0, sort by rarity
                  if (a.amount === 0 && b.amount === 0) {
                    return (
                      emojiRarityOrder.indexOf(a.rarity) -
                      emojiRarityOrder.indexOf(b.rarity)
                    );
                  }

                  // Original sorting logic for non-zero amounts
                  const rarityDiff =
                    emojiRarityOrder.indexOf(a.rarity) -
                    emojiRarityOrder.indexOf(b.rarity);

                  return rarityDiff === 0 ? b.amount - a.amount : rarityDiff;
                })
                .map((emoji) => (
                  <CommandItem
                    key={emoji.id}
                    className="flex items-center space-x-2 p-2"
                    onSelect={() => {
                      setOpen(false);
                      props.onEmojiClick(emoji);
                    }}
                    disabled={emoji.amount === 0 && emoji.rarity !== "CORE"}
                  >
                    <div className="relative">
                      <picture>
                        <source
                          srcSet={`https://fonts.gstatic.com/s/e/notoemoji/latest/${emoji.id}/512.webp`}
                          type="image/webp"
                        />
                        <img
                          src={`https://fonts.gstatic.com/s/e/notoemoji/latest/${emoji.id}/512.gif`}
                          alt={emoji.name}
                          width="32"
                          height="32"
                          className="w-8 h-8"
                        />
                      </picture>
                    </div>

                    <div className="pl-2 flex-1">
                      <p
                        className={`
                            text-sm font-medium
                            ${
                              emoji.rarity === "CORE"
                                ? "text-black"
                                : emoji.rarity === "COMMON"
                                ? "text-green-300"
                                : emoji.rarity === "RARE"
                                ? "text-blue-400"
                                : "text-purple-400"
                            }`}
                      >
                        {emoji.name}
                      </p>
                      <p className="text-xs text-muted-foreground">
                        gives{" "}
                        <span
                          className={`${
                            emoji.rarity === "CORE"
                              ? "text-black"
                              : emoji.rarity === "COMMON"
                              ? "text-green-300"
                              : emoji.rarity === "RARE"
                              ? "text-blue-400"
                              : "text-purple-400"
                          }`}
                        >
                          {emoji.xpReward} XP
                        </span>
                      </p>
                    </div>

                    <div>
                      <p>
                        {emoji.rarity === "CORE" ? "∞" : `${emoji.amount}x`}
                      </p>
                    </div>
                  </CommandItem>
                ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
