import { client } from "../client";

type DeleteTeamRequestDTO = {
  companyId: string;
  teamId: string;
};

export const deleteTeam = async (params: DeleteTeamRequestDTO) => {
  await client.delete(`/companies/${params.companyId}/teams/${params.teamId}`);
};
