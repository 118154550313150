import { client } from "../client";
import { Emoji } from "./types";

export type UserEmoji = Emoji & { amount: number };

type GetUserEmojisResponseDTO = UserEmoji[];

export const getUserEmojiventory = async () => {
  const res = await client.get<GetUserEmojisResponseDTO>("/emojis");

  return res.data;
};
