import { FullTrainingPlan } from "../data/training-plans/types";

export const calculateTotalXPReward = (
  trainingPlan: FullTrainingPlan
): number => {
  let totalRewardXP = 0;

  for (const week of trainingPlan.weeks) {
    const minWeekProgressForReward = week.objective * 0.8;

    if (week.progress >= minWeekProgressForReward) {
      totalRewardXP += week.expReward;
    }
  }

  return totalRewardXP;
};
