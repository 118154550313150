"use client";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { applyXPBoost } from "../../data/activities/apply-xp-boost";
import { getUserXPBoosts } from "../../data/activities/get-xp-boosts";
import { timeUntil } from "../../lib/date-utils";

type ApplyXPBoostProps = {
  activityId: string;
  activityExp: number;
  activityCreatedAt: string;
};

type XPBoost = {
  id: string;
  value: number;
};

type GroupedBoost = {
  value: number;
  count: number;
  boosts: XPBoost[];
};

type FilterRange = "all" | "0-33" | "33-66" | "66+";

export function ApplyXPBoost(props: ApplyXPBoostProps) {
  const navigate = useNavigate();

  const [groupedBoosts, setGroupedBoosts] = useState<GroupedBoost[]>([]);
  const [selectedBoostValue, setSelectedBoostValue] = useState<number | null>(
    null
  );
  const [selectedBoostId, setSelectedBoostId] = useState<string | null>(null);
  const [activeFilter, setActiveFilter] = useState<FilterRange>("all");

  const loadUserXPBoosts = async () => {
    const data = await getUserXPBoosts();
    const unusedBoosts = data.boosts
      .filter((xpb) => !xpb.activityId && !xpb.forgedIntoId)
      .sort((a, b) => b.value - a.value);

    const grouped = unusedBoosts.reduce(
      (acc: GroupedBoost[], boost: XPBoost) => {
        const existingGroup = acc.find((group) => group.value === boost.value);
        if (existingGroup) {
          existingGroup.count++;
          existingGroup.boosts.push(boost);
        } else {
          acc.push({ value: boost.value, count: 1, boosts: [boost] });
        }
        return acc;
      },
      []
    );

    setGroupedBoosts(grouped);
  };

  const onClickApplyXPBoost = async () => {
    if (selectedBoostId) {
      await applyXPBoost({
        xpBoostId: selectedBoostId,
        activityId: props.activityId,
      });

      navigate(0);
    }
  };

  const calculateXPGain = () => {
    if (!selectedBoostValue) {
      return 0;
    }
    return Math.round((props.activityExp * selectedBoostValue) / 100);
  };

  const filterBoosts = (range: FilterRange) => {
    setActiveFilter(range);
    setSelectedBoostValue(null);
    setSelectedBoostId(null);
  };

  const getFilteredBoosts = () => {
    switch (activeFilter) {
      case "0-33":
        return groupedBoosts.filter(
          (group) => group.value > 0 && group.value <= 33
        );
      case "33-66":
        return groupedBoosts.filter(
          (group) => group.value > 33 && group.value <= 66
        );
      case "66+":
        return groupedBoosts.filter((group) => group.value > 66);
      default:
        return groupedBoosts;
    }
  };

  const getBoostCount = (range: FilterRange) => {
    return groupedBoosts.reduce((count, group) => {
      if (range === "all") return count + group.count;
      if (range === "0-33" && group.value > 0 && group.value <= 33)
        return count + group.count;
      if (range === "33-66" && group.value > 33 && group.value <= 66)
        return count + group.count;
      if (range === "66+" && group.value > 66) return count + group.count;
      return count;
    }, 0);
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          size="xs"
          className="bg-blue-400 hover:bg-blue-500"
          onClick={loadUserXPBoosts}
        >
          Apply boost
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold text-black">
            Apply XP Boost
          </DialogTitle>
          <DialogDescription>
            Activity locks{" "}
            {timeUntil(dayjs(props.activityCreatedAt).add(1, "day").toString())}
            .
            <br />
            XP Bonuses are not applied to leaderboards.
          </DialogDescription>
        </DialogHeader>

        {groupedBoosts.length > 0 ? (
          <>
            <div className="space-y-4">
              <div className="flex flex-wrap gap-2">
                {(["0-33", "33-66", "66+", "all"] as FilterRange[]).map(
                  (range) => (
                    <Button
                      key={range}
                      variant={activeFilter === range ? "default" : "outline"}
                      onClick={() => {
                        filterBoosts(range);

                        setSelectedBoostId(null);
                        setSelectedBoostValue(null);
                      }}
                      className="flex-1"
                    >
                      {range === "all" ? "All" : `${range}%`} (
                      {getBoostCount(range)})
                    </Button>
                  )
                )}
              </div>

              <Select
                onValueChange={(value) => {
                  const [boostValue, boostId] = value.split("|");
                  setSelectedBoostValue(Number(boostValue));
                  setSelectedBoostId(boostId);
                }}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select a boost" />
                </SelectTrigger>

                <SelectContent>
                  {getFilteredBoosts().map((group) => (
                    <SelectItem
                      key={group.value}
                      value={`${group.value}|${group.boosts[0].id}`}
                    >
                      {group.value}% Boost (x{group.count})
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="text-xl text-center mt-4">
              {selectedBoostValue
                ? `${selectedBoostValue}% of ${props.activityExp} = `
                : ""}
              <span className="font-bold text-blue-400">
                {calculateXPGain()} XP
              </span>
            </div>
          </>
        ) : (
          <p>No available XP boosts</p>
        )}

        <Button
          className="w-full bg-blue-400 hover:bg-blue-500 text-white mt-4"
          disabled={!selectedBoostId}
          onClick={onClickApplyXPBoost}
        >
          Apply Boost
        </Button>
      </DialogContent>
    </Dialog>
  );
}
