import { ColumnDef, Row } from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CompanyMember } from "../../data/company/get-company-members";
import { leaveCompany } from "../../data/company/leave-company";
import { getFallbackName } from "../../lib/name-utils";
import { useAuthStore } from "../../stores/auth.store";
import Typography from "../typography";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Button } from "../ui/button";
import { DataTable } from "../ui/data-table";
import { DataTableColumnFilter } from "../ui/data-table-column-filter";
import { DataTableColumnHeader } from "../ui/data-table-column-header";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Input } from "../ui/input";

type CompanyMembersTableProps = {
  members: CompanyMember[];
  removeMember: (userId: string) => void;
};

export const CompanyMembersTable = (props: CompanyMembersTableProps) => {
  const navigate = useNavigate();

  const isCompanyAdmin = useAuthStore((store) => store.isCompanyAdmin);

  const [nameFilter, setNameFilter] = useState<string>("");

  const onClickRemoveFromCompany = async (userId: string) => {
    await leaveCompany({
      userId,
    });

    props.removeMember(userId);
  };

  const getColumns = (teams: string[], showActionsColumn: boolean) => {
    const columns: ColumnDef<CompanyMember>[] = [
      {
        header: "Name",
        cell: ({ row }) => (
          <div className="flex flex-row h-full items-center gap-2">
            <Avatar
              className="hover:cursor-pointer"
              onClick={() => navigate(`/profile/${row.original.id}`)}
            >
              <AvatarImage src={row.original.avatarUrl} />
              <AvatarFallback>
                {getFallbackName(row.original.firstName, row.original.lastName)}
              </AvatarFallback>
            </Avatar>

            <Typography
              variant="p"
              affects="link"
              className="text-lg"
              onClick={() => navigate(`/profile/${row.original.id}`)}
            >
              {row.original.firstName} {row.original.lastName}
            </Typography>
          </div>
        ),
      },
      {
        accessorKey: "level",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Level" />
        ),
        cell: ({ row }) => (
          <Typography variant="p" className="text-lg">
            {row.getValue("level")}
          </Typography>
        ),
      },
      {
        id: "Contribution",
        accessorFn: (row) => row.stats.companyContribution,
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Contribution" />
        ),
        cell: ({ row }) => (
          <Typography variant="p" className="text-lg">
            {Math.round(row.original.stats.companyContribution * 100)}%
          </Typography>
        ),
      },
      {
        id: "teamCodeName",
        accessorFn: (row) => row.team?.codeName || "",
        header: ({ column }) => (
          <DataTableColumnFilter
            column={column}
            title="Team"
            options={[
              ...(teams || []).map((teamName) => ({
                label: teamName,
                value: teamName,
              })),
            ]}
          />
        ),
        cell: ({ row }) => (
          <Typography variant="p" className="text-lg">
            {row.original?.team?.codeName || ""}
          </Typography>
        ),
        filterFn: (row, id, value: string[]) => {
          return value.length === 0 || value.includes(row.getValue(id));
        },
      },
      {
        id: "nbActivities",
        accessorFn: (row) => row.stats.nbActivities,
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="# Activities" />
        ),
        cell: ({ row }) => (
          <Typography variant="p" className="text-lg">
            {row.original.stats.nbActivities}
          </Typography>
        ),
      },
      {
        id: "distanceRun",
        accessorFn: (row) => row.stats.runningDistance,
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Distance ran" />
        ),
        cell: ({ row }) => (
          <Typography variant="p" className="text-lg">
            {row.original.stats.runningDistance + " km"}
          </Typography>
        ),
      },
      {
        id: "distanceBike",
        accessorFn: (row) => row.stats.bikingDistance,
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Distance biked" />
        ),
        cell: ({ row }) => (
          <Typography variant="p" className="text-lg">
            {row.original.stats.bikingDistance + " km"}
          </Typography>
        ),
      },
      {
        id: "distanceSwim",
        accessorFn: (row) => row.stats.swimmingDistance,
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Distance swam" />
        ),
        cell: ({ row }) => (
          <Typography variant="p" className="text-lg">
            {row.original.stats.swimmingDistance + " km"}
          </Typography>
        ),
      },
      ...(showActionsColumn
        ? [
            {
              id: "actions",
              cell: ({ row }: { row: Row<CompanyMember> }) => (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="h-8 w-8 p-0">
                      <span className="sr-only">Open menu</span>
                      <MoreHorizontal className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>

                  <DropdownMenuContent align="end">
                    <DropdownMenuLabel>Actions</DropdownMenuLabel>

                    <Dialog>
                      <DialogTrigger asChild>
                        <DropdownMenuItem
                          onSelect={(e) => {
                            // Prevent dropdown menu from closing the dialog
                            e.preventDefault();
                          }}
                        >
                          <Typography
                            variant="p"
                            className="text-xs hover:text-red-400 hover:cursor-pointer"
                          >
                            Remove from company
                          </Typography>
                        </DropdownMenuItem>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogHeader>
                          <DialogTitle>Removing member</DialogTitle>
                          <DialogDescription>
                            Are you sure you want remove this user from your
                            company?
                            <br />
                            They will be able to join again if they have the
                            company join code.
                          </DialogDescription>

                          <DialogFooter>
                            <DialogClose
                              className="flex flex-row justify-end gap-4"
                              asChild
                            >
                              <Button variant="secondary">Cancel</Button>
                            </DialogClose>

                            <Button
                              variant="destructive"
                              onClick={() =>
                                onClickRemoveFromCompany(row.original.id)
                              }
                            >
                              Remove from company
                            </Button>
                          </DialogFooter>
                        </DialogHeader>
                      </DialogContent>
                    </Dialog>
                  </DropdownMenuContent>
                </DropdownMenu>
              ),
            },
          ]
        : []),
    ];

    return columns;
  };

  return (
    <div className="flex flex-col gap-4 bg-white rounded-md border border-slate-200 p-4 mt-4">
      <div className="flex flex-col sm:flex-row w-full justify-between items-start sm:items-center gap-4 sm:gap-0">
        <div className="flex flex-row items-end gap-3">
          <Typography variant="h3">Members</Typography>
          <Typography variant="h3" affects="light" className="text-blue-500">
            {props.members.length}
          </Typography>
        </div>

        <Input
          type="text"
          className="w-full sm:w-64"
          placeholder="Filter members by name..."
          onChange={(value) => setNameFilter(value.currentTarget.value)}
        />
      </div>

      <div className="overflow-x-auto">
        <DataTable
          columns={getColumns(
            [
              ...new Set(
                props.members
                  .flatMap((member) => member.team?.codeName || "")
                  .values()
              ),
            ],
            isCompanyAdmin
          )}
          data={props.members
            .filter(
              (x) =>
                x.firstName.toLowerCase().includes(nameFilter.toLowerCase()) ||
                x.lastName.toLowerCase().includes(nameFilter.toLowerCase())
            )
            .sort((a, b) => a.firstName.localeCompare(b.firstName))}
        />
      </div>
    </div>
  );
};
