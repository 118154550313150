import { client } from "../client";
import { EmojiRarity } from "../emojis/types";
import { ProfileBackground } from "../profile/get-backgrounds";

type ActivityAuthor = {
  id: string;
  firstName: string;
  lastName: string;
  experience: number;
  level: number;
  avatarUrl: string;
  team?: {
    id: string;
    codeName: string;
  };
  profileBackground: ProfileBackground;
};

export type EmojiReaction = {
  id: string;
  fallback: string;
  name: string;
  rarity: EmojiRarity;
  emojiId: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
};

export type Activity = {
  id: string;
  type: string;
  experience: number;
  bonusExp: number;
  xpBoostId?: string;
  distance: number;
  duration: number;
  elevation: number;
  createdAt: string;
  author: ActivityAuthor;
  reactions: EmojiReaction[];
};

export type GetHomeActivitiesResponseDTO = Activity[];

export const getHomeActivities = async () => {
  const res = await client.get<GetHomeActivitiesResponseDTO>(`/activities`);

  return res.data;
};
