import { useEffect, useState } from "react";
import { toast } from "sonner";
import { XPBoost } from "../../../data/activities/get-xp-boosts";
import { MinigameUpgrade } from "../../../data/minigames/get-upgrades";
import { forgeXPBoost } from "../../../data/minigames/xp-forge/forge-xp-boost";
import Typography from "../../typography";
import { Badge } from "../badge";
import { Button } from "../button";
import { Card, CardContent } from "../card";
import { ScrollArea } from "../scroll-area";
import { BoostCard } from "./boost-card";

type ForgeTabProps = {
  boosts: XPBoost[];
  loadBoosts: () => void;
  upgrades: MinigameUpgrade[];
  userEnergy: number;
};

export const ForgeTab = ({
  boosts,
  loadBoosts,
  upgrades,
  userEnergy,
}: ForgeTabProps) => {
  const [forgePrice, setForgePrice] = useState<number>(0);
  const [forgeTotal, setForgeTotal] = useState<number>(0);
  const [forgePotential, setForgePotential] = useState<number>(0);

  const [forgeSlots, setForgeSlots] = useState<(XPBoost | null)[]>([
    null,
    null,
    null,
  ]);

  useEffect(() => {
    const total = forgeSlots.reduce(
      (tot, curr) => (tot += curr?.value || 0),
      0
    );
    setForgeTotal(total);

    const upgradeDiscount = upgrades.find(
      (u) => u.minigameId === "XP_FORGE" && u.upgradeType === "DISCOUNT"
    );
    const upgradeDiscountValue = upgradeDiscount?.currentLevelValue || 0;
    const discount = 1 - upgradeDiscountValue / 100;
    const price = Math.round(total * discount);

    setForgePrice(price);

    const upgradeMultiplier = upgrades.find(
      (u) => u.minigameId === "XP_FORGE" && u.upgradeType === "MIN_MULTIPLIER"
    );
    const upgradeMultiplierValue = upgradeMultiplier?.currentLevelValue || 75;
    const minMultiplier = upgradeMultiplierValue / 100;
    const potential = Math.min(Math.round(total * minMultiplier), 100);

    setForgePotential(potential);
  }, [forgeSlots, upgrades]);

  const handleForgeSlotClick = (index: number) => {
    if (forgeSlots[index]) {
      const newForgeSlots = [...forgeSlots];
      newForgeSlots[index] = null;

      setForgeSlots(newForgeSlots);
    }
  };

  const handleBoostSelect = (boost: XPBoost) => {
    const selectedSlotIndex = forgeSlots.findIndex(
      (slot) => slot?.id === boost.id
    );

    // Clicking on already-selected boost removes it from Forge slots
    if (selectedSlotIndex !== -1) {
      const newForgeSlots = [...forgeSlots];
      newForgeSlots[selectedSlotIndex] = null;

      setForgeSlots(newForgeSlots);

      return;
    }

    const emptySlotIndex = forgeSlots.findIndex((slot) => slot === null);

    if (emptySlotIndex !== -1) {
      const newForgeSlots = [...forgeSlots];
      newForgeSlots[emptySlotIndex] = boost;

      setForgeSlots(newForgeSlots);
    }
  };

  const onClickForge = async () => {
    if (forgeSlots.some((slot) => !slot)) {
      return;
    }

    const forgedBoost = await forgeXPBoost({
      boosts: forgeSlots.map((slot) => slot!.id),
    });

    toast.success("🚀 XP Boost forged!", {
      description: `Successfully forged a ${forgedBoost.value}% XP Boost.`,
      duration: 10_000,
    });

    setForgeSlots([null, null, null]);
    loadBoosts();
  };

  return (
    <div className="w-full h-full bg-white p-2 flex flex-col gap-4">
      <div className="flex flex-col gap-4 items-center">
        <div className="flex flex-col">
          <Typography variant="h3" className="text-center">
            XP Forge
          </Typography>

          <Typography variant="p" affects="muted" className="text-center">
            Select three XP boosts you want to forge into one.
          </Typography>

          {forgeTotal > 100 ? (
            <Typography
              variant="p"
              affects="muted"
              className="text-center text-red-400 animate-pulse"
            >
              ⚠️ The highest an XP Boost can go is 100%.
            </Typography>
          ) : (
            <Typography variant="p" affects="muted" className="text-center">
              The highest an XP Boost can go is 100%.
            </Typography>
          )}
        </div>

        <div className="flex flex-row gap-2 md:gap-4 w-full justify-center">
          {forgeSlots.map((boost, index) => (
            <div
              key={index}
              className="aspect-square bg-accent rounded-md flex items-center justify-center w-24 h-24 md:w-32 md:h-32"
            >
              {boost && (
                <Card
                  className="flex cursor-pointer hover:bg-accent rounded-md items-center justify-center w-20 h-20 md:w-24 md:h-24"
                  onClick={() => handleForgeSlotClick(index)}
                >
                  <CardContent className="p-4 text-center">
                    <p className="text-lg font-bold">{boost.value}%</p>
                  </CardContent>
                </Card>
              )}
            </div>
          ))}
        </div>

        <Button
          className="w-full sm:w-1/2 bg-blue-400 hover:bg-blue-500 text-white"
          disabled={forgeSlots.some((slot) => !slot)}
          onClick={onClickForge}
        >
          Forge boost between {forgePotential}-{Math.min(forgeTotal, 100)}% for{" "}
          {forgePrice} ⚡
        </Button>
      </div>

      <div className="flex flex-col gap-4 overflow-hidden h-full">
        <div className="flex flex-col gap-1 w-full justify-center items-center">
          <Typography variant="h3">Inventory</Typography>

          <Badge
            variant="secondary"
            className="bg-blue-400 bg-opacity-10 text-md w-fit"
          >
            <Typography variant="p" affects="muted" className="text-blue-400">
              {userEnergy} left ⚡
            </Typography>
          </Badge>
        </div>

        <ScrollArea>
          <div className="grid grid-cols-2 gap-2 pb-16 h-full md:pb-0">
            {boosts.map((boost) => (
              <BoostCard
                key={boost.id}
                boost={boost}
                isSelected={forgeSlots.some((slot) => slot?.id === boost.id)}
                onClick={() => handleBoostSelect(boost)}
              />
            ))}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
};
