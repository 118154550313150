export type EmojiRarity = "CORE" | "COMMON" | "RARE" | "SPECIAL";

export const emojiRarityOrder: EmojiRarity[] = [
  "SPECIAL",
  "RARE",
  "COMMON",
  "CORE",
];

export const emojiColorsByRarity: Record<EmojiRarity, string> = {
  CORE: "bg-gray-100 border-gray-200",
  COMMON: "bg-green-100 border-green-200",
  RARE: "bg-blue-100 border-blue-200",
  SPECIAL: "bg-purple-100 border-purple-200",
};

export type Emoji = {
  id: string;
  fallback: string;
  name: string;
  rarity: "CORE" | "COMMON" | "RARE" | "SPECIAL";
  xpReward: number;
};
