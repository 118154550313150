import { CaretSortIcon } from "@radix-ui/react-icons";
import dayjs from "dayjs";
import { CalendarIcon, CheckIcon, GoalIcon } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createTrainingPlan } from "../../data/training-plans/create-training-plan";
import { trainingPlanNames } from "../../data/training-plans/plans";
import { TrainingPlanType } from "../../data/training-plans/types";
import { cn } from "../../lib/utils";
import Typography from "../typography";
import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Label } from "../ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";

export const StartTrainingPlanDialog = () => {
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState<Date>(dayjs().toDate());

  const [planSelectOpen, setPlanSelectOpen] = useState(false);
  const [planSelectValue, setPlanSelectValue] = useState("");
  const [planSelectId, setPlanSelectId] = useState<
    TrainingPlanType | undefined
  >(undefined);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (planSelectId) {
      await createTrainingPlan({
        type: planSelectId,
        startDate,
      });

      navigate(0);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="default" className="rounded-md">
          <GoalIcon className="size-5 mr-2" />
          Start your journey
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-md w-full mx-auto px-2 sm:px-4">
        <DialogHeader>
          <DialogTitle>Start a Training Plan</DialogTitle>

          <DialogDescription>
            Follow Miles' recommendations to get ready for your target distance!
          </DialogDescription>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex flex-row gap-2 w-full">
            <div className="flex flex-col gap-2 w-full">
              <Label>Training Plan</Label>

              <Popover open={planSelectOpen} onOpenChange={setPlanSelectOpen}>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    className="justify-between"
                  >
                    <Typography variant="p" className="text-sm font-normal">
                      {planSelectId
                        ? trainingPlanNames[planSelectId]
                        : "Select training plan..."}
                    </Typography>

                    <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>

                <PopoverContent className="p-0">
                  <Command>
                    <CommandInput
                      placeholder="Search training plan..."
                      className="h-9"
                    />

                    <CommandList>
                      <CommandEmpty>No training plan found.</CommandEmpty>

                      <CommandGroup>
                        {Object.entries(trainingPlanNames).map(
                          ([planId, planName]) => (
                            <CommandItem
                              key={planId}
                              value={planId}
                              keywords={[planId, planName]}
                              onSelect={(currentValue) => {
                                const selectedValue =
                                  currentValue as TrainingPlanType;

                                setPlanSelectId(selectedValue);
                                setPlanSelectValue(
                                  trainingPlanNames[selectedValue]
                                );

                                setPlanSelectOpen(false);
                              }}
                            >
                              {planName}
                              <CheckIcon
                                className={cn(
                                  "ml-auto h-4 w-4",
                                  planSelectValue === planName
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                            </CommandItem>
                          )
                        )}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>

            <div className="flex flex-col gap-2 w-full">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Label className="w-full">🚧 Start Week</Label>
                </TooltipTrigger>

                <TooltipContent className="bg-white text-black border border-gray-200">
                  <p className="max-w-xs">
                    Your training plan will start this week. This is a work in
                    progress feature.
                  </p>
                </TooltipContent>
              </Tooltip>

              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className={`w-full justify-start text-left font-normal ${
                      !startDate && "text-muted-foreground"
                    }`}
                    disabled={true} // reason: training plans always start this week.
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {startDate
                      ? `Starts on ${dayjs(startDate)
                          .startOf("week")
                          .format("MMM D")}`
                      : "Pick a start week"}
                  </Button>
                </PopoverTrigger>

                <PopoverContent className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={startDate}
                    onSelect={(date) => setStartDate(date!)}
                    disabled={(date) => date < new Date()}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>

          <Button
            type="submit"
            className="w-full"
            disabled={!startDate || !planSelectId}
          >
            Start training plan
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
