import { EyeClosedIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { Company, getCompany } from "../../../data/company/get-company";
import { updateCompany } from "../../../data/settings/update-company";
import { getFallbackName } from "../../../lib/name-utils";
import { useAuthStore } from "../../../stores/auth.store";
import Typography from "../../typography";
import { Avatar, AvatarFallback, AvatarImage } from "../avatar";
import { Button } from "../button";
import { Input } from "../input";
import { Label } from "../label";
import { Separator } from "../separator";

export const CompanyTab = () => {
  const isCompanyAdmin = useAuthStore((store) => store.isCompanyAdmin);
  const companyId = useAuthStore((store) => store.companyId);

  const [company, setCompany] = useState<Company>();
  const [isCodeHidden, setIsCodeHidden] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [avatarSrc, setAvatarSrc] = useState<string>("");
  const [avatarFile, setAvatarFile] = useState<File>();

  useEffect(() => {
    const loadCompany = async () => {
      const data = await getCompany({
        companyId,
      });

      setCompany(data);

      setAvatarSrc(data.avatarUrl || "");
    };

    if (isCompanyAdmin) {
      loadCompany();
    }
  }, [companyId, isCompanyAdmin]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setAvatarSrc(imageURL);
      setAvatarFile(file);
    }
  };

  const onClickSave = async () => {
    if (avatarFile) {
      setIsSaving(true);

      await updateCompany({
        companyId,
        avatarFile,
      });

      setIsSaving(false);

      toast.success("Success!", {
        description: "Avatar successfully updated.",
      });
    }
  };

  return (
    <div className="w-full bg-white rounded-md border border-slate-200 p-4 flex flex-col gap-4">
      <div>
        <Typography variant="h3">Company</Typography>
        <Typography variant="p" affects="muted" className="text-sm">
          Manage your company as an administrator.
        </Typography>
      </div>

      <Separator />

      <div className="flex flex-col gap-1">
        <Label>Company code</Label>

        <div className="flex flex-row gap-2 w-full">
          <Input
            type={isCodeHidden ? "password" : "text"}
            value={company?.joinCode || ""}
            disabled
            className="flex-grow"
          />

          <Button
            variant="outline"
            size="icon"
            onClick={() => {
              setIsCodeHidden(!isCodeHidden);
            }}
            className="w-12"
          >
            {isCodeHidden ? (
              <EyeOpenIcon className="w-4 h-4" />
            ) : (
              <EyeClosedIcon className="w-4 h-4" />
            )}
          </Button>

          <Button
            variant="secondary"
            disabled={!company?.joinCode}
            onClick={() => {
              if (company?.joinCode) {
                navigator.clipboard.writeText(company.joinCode);
                toast.success("Code copied to clipboard.", {
                  description:
                    "Employees can use this code to join the company.",
                });
              }
            }}
          >
            Copy
          </Button>
        </div>
      </div>

      <div className="flex flex-row gap-4 w-full">
        <Label className="flex flex-col gap-1">
          <Typography variant="p" affects="small">
            Avatar
          </Typography>
          <Avatar
            size="large"
            affects="noborder"
            className="hover:border-gray-400 hover:border hover:border-dotted"
          >
            <AvatarImage src={avatarSrc} />
            <AvatarFallback>
              {company ? getFallbackName(company.name) : ""}
            </AvatarFallback>
            <input
              id="avatar"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              hidden
            />
          </Avatar>
        </Label>
      </div>

      <div className="w-full flex justify-end">
        <Button className="px-6" onClick={onClickSave} disabled={isSaving}>
          Save
        </Button>
      </div>
    </div>
  );
};
