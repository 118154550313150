import { ArrowLeft, ArrowRight } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "../../../data/minigames/grid-reveal/get-grid-by-id";
import { getFallbackName } from "../../../lib/name-utils";
import { useInterval } from "../../../lib/use-interval.hook";
import { useAuthStore } from "../../../stores/auth.store";
import Typography from "../../typography";
import { Avatar, AvatarFallback, AvatarImage } from "../avatar";
import { Badge } from "../badge";
import { Button } from "../button";

type GridComponentProps = {
  grid: Grid;
  onCellClick: (x: number, y: number) => Promise<boolean>;
  onPreviousLevel: () => void;
  onNextLevel: () => void;
  highlightedCell: { x: number; y: number } | null;
};

export const GameGrid = ({
  grid,
  onCellClick,
  onPreviousLevel,
  onNextLevel,
  highlightedCell,
}: GridComponentProps) => {
  const navigate = useNavigate();

  // Create a 2D array representation of the grid
  const gridArray = Array(8)
    .fill(null)
    .map(() => Array(5).fill(null));
  grid.cells.forEach((cell) => {
    gridArray[cell.y][cell.x] = cell;
  });

  const userId = useAuthStore((store) => store.userId);
  const isMyGrid = grid.user.id === userId;

  const [isAutoClaiming, setIsAutoClaiming] = useState(false);

  const getAutoClaimableCell = () => {
    return grid.cells.find(
      (cell) =>
        !cell.claimedAt &&
        Math.round(cell.energyRequirement * (1 - cell.discount / 100)) <=
          grid.user.energy
    );
  };

  useInterval(
    async () => {
      // Nothing to do if autoclaim is disabled.
      if (!isAutoClaiming) {
        return;
      }

      const cell = getAutoClaimableCell();

      // No cell found, stop the interval
      if (!cell) {
        setIsAutoClaiming(false);
        return;
      }

      // Trigger cell click
      const isSuccess = await onCellClick(cell.x, cell.y);

      if (!isSuccess) {
        setIsAutoClaiming(false);
        return;
      }

      // Small hack to not have to refresh the entire grid or something.
      cell.claimedAt = new Date().toISOString();
    },
    isAutoClaiming ? 500 : null
  );

  const toggleAutoClaim = () => {
    if (isAutoClaiming) {
      setIsAutoClaiming(false);
    } else {
      setIsAutoClaiming(true);
    }
  };

  return (
    <div className="w-full h-full bg-white p-4 flex flex-col justify-between gap-2">
      <div>
        <div className="flex justify-between">
          <Typography variant="h3" className="text-center">
            Grid Reveal
          </Typography>

          <Typography
            variant="p"
            affects="removePMargin"
            className="text-center"
          >
            Level {grid.level} {grid.isFinished ? "✅" : ""}
          </Typography>
        </div>

        <div className="flex md:hidden items-center gap-2">
          <Avatar
            affects="noborder"
            className="hover:cursor-pointer"
            onClick={() => navigate(`/profile/${userId}`)}
          >
            <AvatarImage src={grid.user.avatarUrl} alt="Avatar" />
            <AvatarFallback>
              {getFallbackName(grid.user.firstName, grid.user.lastName)}
            </AvatarFallback>
          </Avatar>

          <div>
            <Typography
              variant="p"
              affects="link"
              onClick={() => navigate(`/profile/${userId}`)}
            >
              {grid.user.firstName} {grid.user.lastName}
            </Typography>
            <Badge
              variant="secondary"
              className="bg-blue-400 bg-opacity-10 text-md"
            >
              <Typography variant="p" affects="muted" className="text-blue-400">
                {grid.user.energy} left ⚡
              </Typography>
            </Badge>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-5 grid-rows-8 gap-2 min-h-min">
        {gridArray.map((row, rowIndex) =>
          row.map((cell, colIndex) => {
            if (!cell)
              return (
                <div
                  key={`${rowIndex}-${colIndex}`}
                  className="aspect-square"
                />
              );

            const cellBackgroundColor = cell.claimedAt
              ? "bg-gray-300"
              : cell.difficulty === "easy"
              ? "bg-blue-100 bg-opacity-20"
              : cell.difficulty === "medium"
              ? "bg-purple-100 bg-opacity-20"
              : "bg-red-100 bg-opacity-20";

            const cellBorderColor =
              cell.difficulty === "easy"
                ? "border-blue-200"
                : cell.difficulty === "medium"
                ? "border-purple-200"
                : "border-red-200";

            const isHighlighted =
              highlightedCell &&
              highlightedCell.x === cell.x &&
              highlightedCell.y === cell.y;

            return (
              <div
                key={`${rowIndex}-${colIndex}`}
                className={`
                      w-14 h-10 md:h-auto md:w-auto md:aspect-square rounded-md border-2 flex flex-col items-center justify-center text-xs md:text-sm lg:text-lg font-semibold
                      ${cell.claimedAt || !isMyGrid ? "" : "cursor-pointer"}
                      ${cellBackgroundColor}
                      ${cellBorderColor}
                      ${isHighlighted ? "ring-2 ring-blue-500" : ""}
                    `}
                onClick={() =>
                  !cell.claimedAt && isMyGrid && onCellClick(cell.x, cell.y)
                }
              >
                <span>
                  {Math.round(
                    cell.energyRequirement * (1 - cell.discount / 100)
                  )}
                </span>
                {!cell.claimedAt && cell.discount > 0 && (
                  <span className="text-xs text-green-600">
                    -{cell.discount}%
                  </span>
                )}
              </div>
            );
          })
        )}
      </div>

      <div className="flex gap-2 justify-between">
        <Button onClick={onPreviousLevel} disabled={!grid.previousGridId}>
          <ArrowLeft />
        </Button>

        <Button
          onClick={toggleAutoClaim}
          disabled={
            grid.cells.filter((c) => !c.claimedAt).length === 0 ||
            !getAutoClaimableCell()
          }
        >
          {isAutoClaiming ? "Stop" : "Start"} Auto Claim
        </Button>

        <Button
          onClick={onNextLevel}
          disabled={!grid.nextGridId && !grid.isFinished}
        >
          <ArrowRight />
        </Button>
      </div>
    </div>
  );
};
